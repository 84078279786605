import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Aligner = makeShortcode("Aligner");
const Button = makeShortcode("Button");
const Process = makeShortcode("Process");
const Link = makeShortcode("Link");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const List = makeShortcode("List");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-the-peoples-narrative",
      "style": {
        "position": "relative"
      }
    }}>{`🎲 The People's Narrative`}<a parentName="h1" {...{
        "href": "#-the-peoples-narrative",
        "aria-label": " the peoples narrative permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`We've investigated the root meanings of tokens and incentives, and travelled back a bit in history to try and understand the mechanisms which have been most successful for the first wave of internet business, as well as the predecessors on whose shoulders they stood. What has come out of this investigation is fascinating: the opportunity which presents itself for how to improve the mechanisms currently in use in businesses like Amazon - i.e. turn every internal bottleneck into a customer-facing platform - revolves around advertising.`}</p>
    <p>{`How can this be?! Advertising is what we want to get rid of as we figure out how to relate more directly and meaningfully, with value that we create, right? It's the single most demonised part of web2. `}</p>
    <p>{`When advertising revenue accrues to one central party, it is addictive, extractive, and represents the sort of perverse incentives we want to avoid replicating. However, advertising which informs me how to most meaningfully participate in communities in ways which benefit me and the people I participate with moves us closer to what David Foster-Wallace once called `}<a parentName="p" {...{
        "href": "https://jsomers.net/DFW_TV.pdf"
      }}>{`“The New Sincerity”`}</a>{`. It is this insight which leads us into our next brief, because we can’t yet look at economic models knowing what we do. We must first turn to what makes any model meaningful to start with: the narrative in which it is grounded.`}</p>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Epsilon Theory`}</strong></p>
        <p><img parentName="p" {...{
            "src": "/images/mod5-img/bankless.png",
            "alt": "The People's Narrative"
          }}></img></p>
        <Aligner center mdxType="Aligner">
          <Button to="https://open.spotify.com/episode/4tQ7PWFqY3KGB5lB8rsJbn" mdxType="Button">
    Listen
          </Button>
        </Aligner>
      </Box>
    </InfoBlock>
    <h3 {...{
      "id": "how-does-this-fit-into-kernel",
      "style": {
        "position": "relative"
      }
    }}>{`How does this fit into Kernel?`}<a parentName="h3" {...{
        "href": "#how-does-this-fit-into-kernel",
        "aria-label": "how does this fit into kernel permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <blockquote>
      <p parentName="blockquote">{`"The world is turning into a place which is valued more by narratives than by fundamentals
and `}{`[Dr Ben Hunt]`}{` caught that switch very early. He's got a lot to teach us about how narratives
and game theory effect asset prices. This is about the base, social layer of the human experience." `}</p>
    </blockquote>
    <p>{`This conversation will help you deepen your approach to:`}</p>
    <Process mdxType="Process">
      <p>{`How narrative creates value`}</p>
      <p>{`Value investing and how it can be brought back with distributed ledgers`}</p>
      <p>{`Game theory and a greater appreciation of the different games we all play daily`}</p>
    </Process>
    <h2 {...{
      "id": "brief",
      "style": {
        "position": "relative"
      }
    }}>{`Brief`}<a parentName="h2" {...{
        "href": "#brief",
        "aria-label": "brief permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p><em parentName="p">{`The actual podcast begins at 6:42, if you're not interested in the build-up.`}</em></p>
    <p>{`This dialogue revolves around an idea called `}<Link to="https://www.epsilontheory.com/epsilon-theory-manifesto/" mdxType="Link">{`Epsilon Theory`}</Link>{`,
which is basically game theory as it applies to `}<strong parentName="p">{`other players`}</strong>{` in the context of stock
markets and investing. As the saying goes in poker: you don't play the cards, you play the
player. That is, the smartest move is often not necessarily to invest in the stock with the
best fundamentals; it's to invest in the stock you think everyone else `}<em parentName="p">{`believes`}</em>{` to have the
best fundamentals. When everyone thinks this way, it gets even more complicated and you end up
with things like `}<Link to="https://www.vanityfair.com/news/2020/06/bankrupt-hertz-is-a-pandemic-zombie" mdxType="Link">{`Hertz`}</Link>{`
or `}<Link to="https://www.fool.com/investing/2020/08/02/elon-musk-was-right-teslas-stock-price-is-too-high.aspx" mdxType="Link">{`TSLA`}</Link>{`.`}</p>
    <p>{`Traditionally, we can model the return on any investment we make with the formula:`}</p>
    <pre><code parentName="pre" {...{}}>{`y = α + β + ε
`}</code></pre>
    <p>{`where `}<em parentName="p">{`y`}</em>{` is the overall return; `}<em parentName="p">{`alpha`}</em>{` is the excess return relative to the market; `}<em parentName="p">{`beta`}</em>{` is
essentially the market rate; and `}<em parentName="p">{`epsilon`}</em>{` is the "error" or, more prosaically, "everything
else" that doesn't fit into the traditional model.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"There is a lot that we don't understand. Just because we can't formalize it, doesn't mean
that it's not real. It doesn't mean that it's `}<em parentName="p">{`error`}</em>{`; it means that it's outside the current
scope of our thinking `}{`[...]`}{` In investing, a lot of what is left out of `}<em parentName="p">{`alpha`}</em>{` and `}<em parentName="p">{`beta`}</em>{` is
the `}<strong parentName="p">{`strategic behaviour of market participants`}</strong>{`."`}</p>
    </blockquote>
    <h3 {...{
      "id": "common-knowledge",
      "style": {
        "position": "relative"
      }
    }}>{`Common knowledge`}<a parentName="h3" {...{
        "href": "#common-knowledge",
        "aria-label": "common knowledge permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Dr Ben Hunt makes the claim that `}<em parentName="p">{`epsilon`}</em>{` is the term which captures `}<strong parentName="p">{`other people's behaviour`}</strong>{`.
In order to demonstrate this, he cites a lesser-known game, beloved by John Maynard Keynes: the
`}<strong parentName="p">{`common knowledge`}</strong>{` `}<strong parentName="p">{`game`}</strong>{`. Back in the day, newspapers held beauty contests where they would
post 10 pictures of beautiful women and have the public write in and vote on who was the prettiest lady in the land (Level 1). However, you might be rewarded if you voted for the winner. Which means, you ought to vote not for who you actually feel is prettiest, but who you feel everyone else will vote for as prettiest (Level 2). Level 3 is `}<em parentName="p">{`everyone figuring out what everyone else thinks`}</em>{` about who the prettiest girl is, much like the stock market today. It's a question of what the consensus about the consensus is. It's game theory for crowds!`}</p>
    <blockquote>
      <p parentName="blockquote">{`"I think everyone's woken up - or been forced to realise - that the old rules don't work. Frankly, they never did: it's just that we all believed they did `}{`[...]`}{` Everyone's now thinking in terms of `}<strong parentName="p">{`narrative`}</strong>{`, and playing the player in these strategic interactions, which I think is so important for understanding the real power structures in the world and what we can do to change them.""`}</p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: Common knowledge games are how we model what?`}</p>
        <p>{`Other people's behavior (or: the strategic behaviour of market participants).`}</p>
      </Card>
    </Flash>
    <p>{`Consider an island of blue-eyed people. On this island, it is taboo to a) have eyes which are
not blue and b) speak of anyone's eye colour. If you have eyes which are not blue, you must
leave the island the next day. Because of these conditions - even though there may be some
green-eyed renegades present - there is no way of telling them apart, so everyone stays and lives
happily together. However, one day, a `}<strong parentName="p">{`missionary`}</strong>{` arrives on the island. Not bound by the
same taboos, he studies the local society and then stands up on his soapbox and ruins everything
by announcing that "There are people on this island with green eyes!"`}</p>
    <p>{`Of course, he's just saying what everyone already knows. However, now everyone knows that
everyone else knows. That is, the missionary has established common knowledge. If there is only
one green-eyed person, it's immediately obvious who it is, and they will leave the next day.
If both you and I have green eyes, what happens? I look at you, see your green eyes, assume you're the one the missionary referred to and that you'll leave the next day. However, tomorrow comes and you're still there. Seeing as there is no-one else with green eyes, I must conclude that I also have green eyes and (having gone through the same internal thought process), you and I both leave `}<em parentName="p">{`at the same time`}</em>{`, two days later. If three of us have green eyes, we will all leave together three days later, and so on. For `}<strong parentName="p">{`n`}</strong>{` people with green eyes, we all leave together `}<strong parentName="p">{`n`}</strong>{` days later.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"This process of creating common knowledge, and then all of us looking around saying, 'Am I the
only one who's out of step here?' is the way markets work: nothing, nothing, nothing and then -
all of a sudden - boom! Everyone with green eyes leaves. There are `}<em parentName="p">{`time`}</em>{` and `}<em parentName="p">{`magnitude`}</em>{`
elements to the creation of common knowledge and the way it impacts a market. `}<strong parentName="p">{`The more people
who share the non-common view, the longer it takes to break the narrative, but the bigger
that break will be`}</strong>{`.""`}</p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: What must we think in terms of in order to play the player and understand how power structures really operate?`}</p>
        <p>{`Narrative.`}</p>
      </Card>
      <Card mdxType="Card">
        <p>{`Prompt: What two elements are involved in the creation of common knowledge and its impact on markets? `}</p>
        <p>{`Time and magnitude.`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "social-uses",
      "style": {
        "position": "relative"
      }
    }}>{`Social uses`}<a parentName="h3" {...{
        "href": "#social-uses",
        "aria-label": "social uses permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Our ability - given the fire hose of data we are faced with every day, and the processing power
we now have - to visualize the structure of crowd narratives is unprecedented. It's no mistake
that the four most successful species - ants, termites, bees and humans - are all social animals.
The `}<em parentName="p">{`sine qua non`}</em>{` of what this means is existing within "an ocean" of intraspecies
communication. When we can map these narrative spaces, we can better predict and anticipate
human behaviour.`}</p>
    <p>{`The question is not whether predictive models like discounted cash flows are right or wrong; it's whether they are `}<em parentName="p">{`useful`}</em>{`. For instance, Newton wasn't wrong, it's just that his equations have turned out to be special cases of more general phenomena. The same applies for markets and econometric theory. `}</p>
    <p>{`The conversation moves into a discussion of the 2008 crisis and the `}<strong parentName="p">{`Federal Reserve as
missionary`}</strong>{`, creating the narrative that they will always backstop the market via "open market
operations" like buying the Mortgage-Backed Securities `}<a parentName="p" {...{
        "href": "/learn/module-2/banking/#banks-and-shadows"
      }}>{`Perry Mehrling talked about`}</a>{`. Dr Hunt points out that this really began in 2009 with the Temporary Liquidity Guarantee Program, which was a set of `}<strong parentName="p">{`actions`}</strong>{` whereby they put the "full faith and credit of the US government behind the unbacked credit of federally chartered banks" - that is, Goldman could stand up the next day and issue $30bn of corporate debt, co-signed by the government.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The pleasant skin of capitalism was ripped off in 2008 and I could see the naked sinews of
power below that `}{`[...]`}{` And now again, created over that, is the new skin of narrative and
missionary work - we're `}<em parentName="p">{`saving the economy`}</em>{`.`}</p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: What ironic name is given to the "operations" of the Federal Reserve when it acts as missionary?`}</p>
        <p>{`Open market.`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "the-nudge",
      "style": {
        "position": "relative"
      }
    }}>{`The nudge`}<a parentName="h3" {...{
        "href": "#the-nudge",
        "aria-label": "the nudge permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Dr Hunt speaks about the "nudging oligarchy" and the "nudging state" - that is, we don't live
in 1984 with the government as a jack-booted thug, crushing out all resistance. It's much more
like the smiling badge with a few drops of blood from `}<em parentName="p">{`Watchmen`}</em>{`, whose ultimate goal is
self-censorship. This is Bentham's panopticon `}<em parentName="p">{`par excellence.`}</em></p>
    <p>{`Moreover, it is the panopticon enhanced with Moore's Law when you consider Central Bank Digital
Currencies and social credit systems. Dr Hunt claims that a government `}<em parentName="p">{`is defined`}</em>{` by control
of the money supply and argues that it will co-opt the clever parts of technologies like
Bitcoin to suit the ends of those in power.`}</p>
    <p>{`However, be wary when you hear people talk about the "strategy" of Bitcoin, or the "intention"
of decentralized networks. Bitcoin and Ethereum have no strategy - they are means of `}<a parentName="p" {...{
        "href": "/learn/module-4/the-garden/#no-more-guards"
      }}>{`ordering
transactional facts in time`}</a>{` such that no-one can claim ownership of either order or fact. Listen to `}<a parentName="p" {...{
        "href": "/learn/module-2/better-questions/#68-life-lessons"
      }}>{`Kevin Kelly`}</a>{`: "there is no them". There is - to use Dr Hunt's own words - only one crowd trying to look in the mirror to figure out what the crowd truly thinks.`}</p>
    <h3 {...{
      "id": "the-long-game",
      "style": {
        "position": "relative"
      }
    }}>{`The long game`}<a parentName="h3" {...{
        "href": "#the-long-game",
        "aria-label": "the long game permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Perhaps the ability to exit - this notion of abandoning the system - is exactly the sort of
check we need to prevent nudging states from moving too far, and exerting undue influence over
the citizenry's autonomy? Dr Hunt thinks this is true, but cautions that one doesn't want to
end up in the "gold bug" situation of sitting in a bunker hoping for a catastrophe, as it is
not a pleasant state of mind in which to pass your life. There is a longer game at play here,
and it doesn't take place on "the battlefield of money", where one is first ghetto-ized and
then outlawed.`}</p>
    <p>{`Distributed ledger technologies attack the Achilles heel of the nudging nation state, by
solving `}<strong parentName="p"><a parentName="strong" {...{
          "href": "/learn/module-0/trust/"
        }}>{`distributed trust`}</a></strong>{`. This is at the core of Dr Hunt's
movement around investing and citizenship, and what Epsilon Theory is really about. Distributed
trust is the Big Game`}<sup>{`TM`}</sup>{`.`}</p>
    <p>{`Let's consider three possible outcomes: `}</p>
    <Process mdxType="Process">
    
Competition between nations leads to adoption of public, decentralized networks; 
      <p>{`Such networks become so useful that - like TCP/IP - nation states `}<em parentName="p">{`must`}</em>{` adopt them to stay relevant; or `}</p>
      <p>{`There is a generational narrative shift led by disenfranchised youth. `}</p>
    </Process>
    <p>{`Dr Hunt thinks the first two are unlikely: strong nations will only compete
to dethrone the Dollar if they can replace it with their own currency, and weak nations "do
what they must" to survive. Usefulness gets co-opted such that you become just another game in
the "Wall Street casino" and change nothing fundamental, `}<a parentName="p" {...{
        "href": "/learn/module-3/time/#conspiring-against-the-actual-os"
      }}>{`like Rushkoff bemoaned`}</a>{`. However,`}</p>
    <blockquote>
      <p parentName="blockquote">{`I honestly think that there is an enormous reservoir of desire for an alternative financial
system or network of investors and markets and trading that is outside of the public market
casino utility that we have today and that `}{`[Ethereum]`}{` has developed an integral part of that
`}{`[...]`}{` There is a natural alliance, that can be cemented with the right narratives, between
value investors and the disenfranchised young generations which could shake the current
financial system and create something interesting.`}</p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: How can we undermine the actions of nudging nation states?`}</p>
        <p>{`By distributing trust.`}</p>
      </Card>
    </Flash>
    <blockquote>
      <p parentName="blockquote">{`💡 This is the `}<a parentName="p" {...{
          "href": "/learn/module-3/intention/#evolving-love"
        }}>{`evolution of our revolution`}</a>{`.`}</p>
    </blockquote>
    <h3 {...{
      "id": "there-is-hope",
      "style": {
        "position": "relative"
      }
    }}>{`There is hope`}<a parentName="h3" {...{
        "href": "#there-is-hope",
        "aria-label": "there is hope permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`And it arises from linking together people from all around the world in what is known as an
`}<strong parentName="p">{`epistemic community`}</strong>{` - a community of knowledge. The ability to create such `}<a parentName="p" {...{
        "href": "https://otherinter.net/squad-wealth/"
      }}>{`squads`}</a>{` is new to the world, and it is cause for great optimism, even in the face of the power of nudging states and oligarchies. This is ultimately about finding the people who are in this world, but not of it:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Good-hearted, but disaffected people, who want liberty and justice for all. Imagine that!`}</p>
    </blockquote>
    <h3 {...{
      "id": "passing-references-worth-following",
      "style": {
        "position": "relative"
      }
    }}>{`Passing references worth following`}<a parentName="h3" {...{
        "href": "#passing-references-worth-following",
        "aria-label": "passing references worth following permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <List mdxType="List">
      <Link to="https://en.wikipedia.org/wiki/Stag_hunt" mdxType="Link">
        <p>{`Stag hunts`}</p>
      </Link>
      <Link to="https://blog.zkga.me/announcing-darkforest" mdxType="Link">
        <p>{`Incomplete information games`}</p>
      </Link>
      <Link to="https://en.wikipedia.org/wiki/Foundation_series" mdxType="Link">
        <p>{`The Second Foundation`}</p>
      </Link>
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      